import $ from "jquery";
import toastr from "toastr";
import Icon from "./../media/images/Logo.png";
// eslint-disable-next-line
export function onSignIn(response) {
  const responsePayload = parseJwt(response.credential);
  let timeout = 1000;
  let fetchUrl = "cp/app/validate.php";
  $.ajax({
    url: fetchUrl,
    type: "POST",
    data: {
      data: JSON.stringify(responsePayload),
    },
    success: function (result) {
      response = JSON.parse(result);
      if (response.code !== 0) {
        toastr.info(response.message, "Failed!");
        return;
      }
      toastr.success(
        "Have Fun " + responsePayload.given_name + "!",
        "Welcome to Reno"
      );
      setTimeout(function () {
        window.location.replace(response.redirect);
      }, timeout);
    },
  });
}

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

window.onload = function () {
  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.rel = "icon";
  link.href = Icon;
  document.getElementsByTagName("head")[0].appendChild(link);

  document.getElementById("logoFrontAndCenter").src = Icon;
};